import React, { useState } from "react";

import {
  Breadcrumb,
  Button,
  Col,
  Container,
  Form,
  Row,
  Tabs,
  Tab,
  Spinner,
} from "react-bootstrap";

import HeaderBg from "../blocks/HeaderBg";
import Anchor from "../elements/Anchor";
import { useCustomer } from "../../context/CustomerContext";
import { Account, Home, Map } from "../../helpers/Icons";
import { functionsBase } from "../../../config/site-config";
import { isValidEmail, isValidPhone } from "../../helpers/helpers";

const ProfileInformations = () => {
  const { customer, setCustomer } = useCustomer();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorEmail, setErrorEmail] = useState(null);
  const [errorPhone, setErrorPhone] = useState(null);

  const [name, setName] = useState(customer.name || "");
  const [email, setEmail] = useState(customer.email || "");
  const [phone, setPhone] = useState(customer.phone || "");
  const [line1, setLine1] = useState(customer.address?.line1 || "");
  const [line2, setLine2] = useState(customer.address?.line2 || "");
  const [postalCode, setPostalCode] = useState(
    customer.address?.postal_code || ""
  );
  const [city, setCity] = useState(customer.address?.city || "");
  const [country] = useState("GR");

  const [shippingLine1, setShippingLine1] = useState(
    customer.shipping?.address?.line1 || ""
  );
  const [shippingLine2, setShippingLine2] = useState(
    customer.shipping?.address?.line2 || ""
  );
  const [shippingPostalCode, setShippingPostalCode] = useState(
    customer.shipping?.address?.postal_code || ""
  );
  const [shippingCity, setShippingCity] = useState(
    customer.shipping?.address?.city || ""
  );
  const [sameShipping, setSameShipping] = useState(
    customer.address?.line1 === customer.shipping?.address.line1
  );

  const handleSave = () => {
    setLoading(true);
    setError(false);
    setErrorEmail(false);
    setErrorPhone(false);

    if (!isValidEmail(email)) setErrorEmail(true);
    if (!isValidPhone(phone)) setErrorPhone(true);

    if (errorEmail || errorPhone) {
      return setLoading(false);
    }

    fetch(`${functionsBase}/update-customer`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        cus_id: customer.id,
        params: {
          name,
          email,
          phone,
          address: {
            city,
            country,
            line1,
            line2,
            postal_code: postalCode,
          },
          shipping: {
            name,
            phone,
            address: {
              city: sameShipping ? city : shippingCity,
              country,
              line1: sameShipping ? line1 : shippingLine1,
              line2: sameShipping ? line2 : shippingLine2,
              postal_code: sameShipping ? postalCode : shippingPostalCode,
            },
          },
        },
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.error) return setError(true);
        if (res.customer) setCustomer(res.customer);
        return null;
      })
      .catch(() => setError(true))
      .finally(() => setLoading(false));

    return null;
  };

  return (
    <article>
      <HeaderBg>
        <h1 className="fw-light text-primary">Το προφίλ μου</h1>
        <p className="text-muted">
          Δείτε και επεξεργαστείτε όλες τις λεπτομέρειες του προφίλ σας
        </p>
        <Breadcrumb className="d-flex justify-content-center">
          <Breadcrumb.Item
            linkAs={Anchor}
            href="/account/dashboard/"
            title="Λογαριασμός"
          >
            <Home size={15} /> Λογαριασμός
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Προφίλ</Breadcrumb.Item>
        </Breadcrumb>
      </HeaderBg>
      <Container as="section" className="mw-1000 pt-5">
        <Form>
          <Row>
            <Col md={6}>
              <div className="d-flex align-items-center mb-5">
                <div className="p-3 shadow rounded">
                  <Account size={30} color="var(--bs-primary)" />
                </div>
                <h2 className="h5 ms-3 mb-0">Τα στοιχεία μου</h2>
              </div>
              <Form.Group className="mb-3" controlId="profileForm.name">
                <Form.Label className="mb-0 text-muted">
                  Όνοματεπώνυμο
                </Form.Label>
                <Form.Control
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Όνοματεπώνυμο"
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="profileForm.email">
                <Form.Label className="mb-0 text-muted">
                  Διεύθυνση Email*
                </Form.Label>
                <Form.Control
                  type="email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  isInvalid={errorEmail}
                  placeholder="Email"
                />
                {errorEmail && (
                  <Form.Control.Feedback type="invalid">
                    Η διεύθυνση email δεν είναι έγκυρη.
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <Form.Group className="mb-3" controlId="profileForm.email">
                <Form.Label className="mb-0 text-muted">Τηλέφωνο*</Form.Label>
                <Form.Control
                  type="tel"
                  value={phone}
                  onChange={(e) => {
                    setPhone(e.target.value.replace(/\s/g, ""));
                  }}
                  isInvalid={errorPhone}
                  placeholder="Τηλέφωνο"
                />
                {errorPhone && (
                  <Form.Control.Feedback type="invalid">
                    Ο αριθμός τηλεφώνου δεν είναι έγκυρος.
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            <Col md={6} className="mt-5 mt-md-0">
              <div className="d-flex align-items-center mb-5">
                <div className="p-3 shadow rounded">
                  <Map size={40} color="var(--bs-primary)" />
                </div>
                <h2 className="h5 ms-3 mb-0">Η διεύθυνσή μου</h2>
              </div>
              <Tabs
                defaultActiveKey="charge"
                id="profile-addresses-tab"
                transition
              >
                <Tab eventKey="charge" title="Χρέωσης" className="pt-3">
                  <Form.Group
                    className="mb-3"
                    controlId="profileForm.addressLine1"
                  >
                    <Form.Label className="mb-0 text-muted">
                      Οδός και αριθμός 1
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={line1}
                      onChange={(e) => setLine1(e.target.value)}
                      placeholder="Οδός και αριθμός"
                    />
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId="profileForm.addressLine2"
                  >
                    <Form.Label className="mb-0 text-muted">
                      Οδός και αριθμός 2
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={line2}
                      onChange={(e) => setLine2(e.target.value)}
                      placeholder="Οδός και αριθμός"
                    />
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId="profileForm.postalCode"
                  >
                    <Form.Label className="mb-0 text-muted">
                      Ταχυδρομικός κώδικας
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={postalCode}
                      onChange={(e) => setPostalCode(e.target.value)}
                      placeholder="Τ.Κ."
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="profileForm.city">
                    <Form.Label className="mb-0 text-muted">Πόλη</Form.Label>
                    <Form.Control
                      type="text"
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                      placeholder="Πόλη"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="profileForm.country">
                    <Form.Label className="mb-0 text-muted">Χώρα</Form.Label>
                    <Form.Select defaultValue="GR" aria-label="Χώρα">
                      <option value="GR">Ελλάδα</option>
                    </Form.Select>
                  </Form.Group>
                </Tab>
                <Tab eventKey="shipping" title="Αποστολής">
                  <Form.Check
                    type="switch"
                    id="profileForm.sameShippingAddress"
                    className="my-4"
                    label="Η διεύθυνση αποστολής είναι ίδια με την διεύθυνση χρέωσης"
                    checked={sameShipping}
                    onChange={() => setSameShipping(!sameShipping)}
                  />
                  {!sameShipping && (
                    <>
                      <Form.Group
                        className="mb-3"
                        controlId="profileForm.shippingAddressLine1"
                      >
                        <Form.Label className="mb-0 text-muted">
                          Οδός και αριθμός 1
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={shippingLine1}
                          onChange={(e) => setShippingLine1(e.target.value)}
                          placeholder="Οδός και αριθμός"
                        />
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="profileForm.shippingAddressLine2"
                      >
                        <Form.Label className="mb-0 text-muted">
                          Οδός και αριθμός 2
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={shippingLine2}
                          onChange={(e) => setShippingLine2(e.target.value)}
                          placeholder="Οδός και αριθμός"
                        />
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="profileForm.shippingPostalCode"
                      >
                        <Form.Label className="mb-0 text-muted">
                          Ταχυδρομικός κώδικας
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={shippingPostalCode}
                          onChange={(e) =>
                            setShippingPostalCode(e.target.value)
                          }
                          placeholder="Τ.Κ."
                        />
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="profileForm.shippingCity"
                      >
                        <Form.Label className="mb-0 text-muted">
                          Πόλη
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={shippingCity}
                          onChange={(e) => setShippingCity(e.target.value)}
                          placeholder="Πόλη"
                        />
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="profileForm.shippingCountry"
                      >
                        <Form.Label className="mb-0 text-muted">
                          Χώρα
                        </Form.Label>
                        <Form.Select defaultValue="GR" aria-label="Χώρα">
                          <option value="GR">Ελλάδα</option>
                        </Form.Select>
                      </Form.Group>
                    </>
                  )}
                </Tab>
              </Tabs>
            </Col>
          </Row>
          <div className="mt-5 text-center">
            {error && (
              <p className="text-danger text-center">
                Παρουσιάστηκε κάποιο πρόβλημα. Προσπαθήστε ξανά αργότερα.
              </p>
            )}
            <Button className="m-2" onClick={handleSave} disabled={loading}>
              {loading && <Spinner size="sm" animation="grow" />} Αποθήκευση
            </Button>
          </div>
        </Form>
      </Container>
    </article>
  );
};

export default ProfileInformations;
