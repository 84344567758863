import React from "react";

import { Col, ListGroup, Row, Placeholder } from "react-bootstrap";

const InvoiceListPlaceholder = () => {
  return (
    <ListGroup variant="flush">
      <ListGroup.Item>
        <Row>
          <Col>
            <Placeholder
              animation="glow"
              style={{ display: "block", width: "90px" }}
            >
              <Placeholder
                as="div"
                className="rounded"
                style={{
                  width: "100%",
                  height: "20px",
                  marginRight: "1rem",
                  backgroundColor: "var(--bs-primary)",
                }}
              />
            </Placeholder>
          </Col>
          <Col>
            <Placeholder
              animation="glow"
              style={{ display: "block", width: "90px" }}
            >
              <Placeholder
                as="div"
                style={{
                  width: "100%",
                  height: "20px",
                  marginRight: "1rem",
                  backgroundColor: "var(--bs-gray-300)",
                }}
              />
            </Placeholder>
          </Col>
          <Col>
            <Placeholder
              animation="glow"
              style={{ display: "block", width: "60px" }}
            >
              <Placeholder
                as="div"
                style={{
                  width: "100%",
                  height: "20px",
                  marginRight: "1rem",
                  backgroundColor: "var(--bs-gray-300)",
                }}
              />
            </Placeholder>
          </Col>
          <Col>
            <Placeholder
              animation="glow"
              style={{ display: "block", width: "110px" }}
            >
              <Placeholder
                as="div"
                style={{
                  width: "100%",
                  height: "20px",
                  marginRight: "1rem",
                  backgroundColor: "var(--bs-gray-300)",
                }}
              />
            </Placeholder>
          </Col>
          <Col className="text-end">
            <Placeholder
              animation="glow"
              style={{ display: "block", width: "110px", marginLeft: "auto" }}
            >
              <Placeholder
                style={{
                  width: "100%",
                  height: "20px",
                  backgroundColor: "var(--bs-primary)",
                }}
              />
            </Placeholder>
          </Col>
        </Row>
      </ListGroup.Item>
      <ListGroup.Item>
        <Row>
          <Col>
            <Placeholder
              animation="glow"
              style={{ display: "block", width: "90px" }}
            >
              <Placeholder
                as="div"
                className="rounded"
                style={{
                  width: "100%",
                  height: "20px",
                  marginRight: "1rem",
                  backgroundColor: "var(--bs-primary)",
                }}
              />
            </Placeholder>
          </Col>
          <Col>
            <Placeholder
              animation="glow"
              style={{ display: "block", width: "90px" }}
            >
              <Placeholder
                as="div"
                style={{
                  width: "100%",
                  height: "20px",
                  marginRight: "1rem",
                  backgroundColor: "var(--bs-gray-300)",
                }}
              />
            </Placeholder>
          </Col>
          <Col>
            <Placeholder
              animation="glow"
              style={{ display: "block", width: "60px" }}
            >
              <Placeholder
                as="div"
                style={{
                  width: "100%",
                  height: "20px",
                  marginRight: "1rem",
                  backgroundColor: "var(--bs-gray-300)",
                }}
              />
            </Placeholder>
          </Col>
          <Col>
            <Placeholder
              animation="glow"
              style={{ display: "block", width: "110px" }}
            >
              <Placeholder
                as="div"
                style={{
                  width: "100%",
                  height: "20px",
                  marginRight: "1rem",
                  backgroundColor: "var(--bs-gray-300)",
                }}
              />
            </Placeholder>
          </Col>
          <Col className="text-end">
            <Placeholder
              animation="glow"
              style={{ display: "block", width: "110px", marginLeft: "auto" }}
            >
              <Placeholder
                style={{
                  width: "100%",
                  height: "20px",
                  backgroundColor: "var(--bs-primary)",
                }}
              />
            </Placeholder>
          </Col>
        </Row>
      </ListGroup.Item>
      <ListGroup.Item>
        <Row>
          <Col>
            <Placeholder
              animation="glow"
              style={{ display: "block", width: "90px" }}
            >
              <Placeholder
                as="div"
                className="rounded"
                style={{
                  width: "100%",
                  height: "20px",
                  marginRight: "1rem",
                  backgroundColor: "var(--bs-primary)",
                }}
              />
            </Placeholder>
          </Col>
          <Col>
            <Placeholder
              animation="glow"
              style={{ display: "block", width: "90px" }}
            >
              <Placeholder
                as="div"
                style={{
                  width: "100%",
                  height: "20px",
                  marginRight: "1rem",
                  backgroundColor: "var(--bs-gray-300)",
                }}
              />
            </Placeholder>
          </Col>
          <Col>
            <Placeholder
              animation="glow"
              style={{ display: "block", width: "60px" }}
            >
              <Placeholder
                as="div"
                style={{
                  width: "100%",
                  height: "20px",
                  marginRight: "1rem",
                  backgroundColor: "var(--bs-gray-300)",
                }}
              />
            </Placeholder>
          </Col>
          <Col>
            <Placeholder
              animation="glow"
              style={{ display: "block", width: "110px" }}
            >
              <Placeholder
                as="div"
                style={{
                  width: "100%",
                  height: "20px",
                  marginRight: "1rem",
                  backgroundColor: "var(--bs-gray-300)",
                }}
              />
            </Placeholder>
          </Col>
          <Col className="text-end">
            <Placeholder
              animation="glow"
              style={{ display: "block", width: "110px", marginLeft: "auto" }}
            >
              <Placeholder
                style={{
                  width: "100%",
                  height: "20px",
                  backgroundColor: "var(--bs-primary)",
                }}
              />
            </Placeholder>
          </Col>
        </Row>
      </ListGroup.Item>
      <ListGroup.Item>
        <Row>
          <Col>
            <Placeholder
              animation="glow"
              style={{ display: "block", width: "90px" }}
            >
              <Placeholder
                as="div"
                className="rounded"
                style={{
                  width: "100%",
                  height: "20px",
                  marginRight: "1rem",
                  backgroundColor: "var(--bs-primary)",
                }}
              />
            </Placeholder>
          </Col>
          <Col>
            <Placeholder
              animation="glow"
              style={{ display: "block", width: "90px" }}
            >
              <Placeholder
                as="div"
                style={{
                  width: "100%",
                  height: "20px",
                  marginRight: "1rem",
                  backgroundColor: "var(--bs-gray-300)",
                }}
              />
            </Placeholder>
          </Col>
          <Col>
            <Placeholder
              animation="glow"
              style={{ display: "block", width: "60px" }}
            >
              <Placeholder
                as="div"
                style={{
                  width: "100%",
                  height: "20px",
                  marginRight: "1rem",
                  backgroundColor: "var(--bs-gray-300)",
                }}
              />
            </Placeholder>
          </Col>
          <Col>
            <Placeholder
              animation="glow"
              style={{ display: "block", width: "110px" }}
            >
              <Placeholder
                as="div"
                style={{
                  width: "100%",
                  height: "20px",
                  marginRight: "1rem",
                  backgroundColor: "var(--bs-gray-300)",
                }}
              />
            </Placeholder>
          </Col>
          <Col className="text-end">
            <Placeholder
              animation="glow"
              style={{ display: "block", width: "110px", marginLeft: "auto" }}
            >
              <Placeholder
                style={{
                  width: "100%",
                  height: "20px",
                  backgroundColor: "var(--bs-primary)",
                }}
              />
            </Placeholder>
          </Col>
        </Row>
      </ListGroup.Item>
    </ListGroup>
  );
};

export default InvoiceListPlaceholder;
