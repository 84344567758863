import React, { useState } from "react";

import {
  Button,
  ListGroup,
  OverlayTrigger,
  Spinner,
  Tooltip,
} from "react-bootstrap";

import Anchor from "./Anchor";
import { useCustomer } from "../../context/CustomerContext";
import { functionsBase } from "../../../config/site-config";
import { Currency, dateFormat } from "../../helpers";
import { BoxLarge, BoxMedium, BoxSmall } from "../../helpers/Icons";

const PlanListItem = ({ subscription }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const { subscriptions, setSubscriptions } = useCustomer();
  const { id, plan, current_period_end: currentPeriodEnd } = subscription;

  const handleCancelation = (subId) => {
    setLoading(true);
    setError(false);

    fetch(`${functionsBase}/cancel-subscription`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        subscriptionId: subId,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.error) return setError(true);
        if (res.deleted) {
          const filteredSubscriptions = subscriptions.filter(
            (sub) => sub.id !== res.deleted.id
          );
          return setSubscriptions(filteredSubscriptions);
        }
        return null;
      })
      .catch(() => {
        setError(true);
        setLoading(false);
      });
  };

  let boxIcon;
  switch (plan.transform_usage?.divide_by) {
    case 2:
      boxIcon = <BoxSmall />;
      break;
    case 4:
      boxIcon = <BoxMedium />;
      break;
    case 6:
      boxIcon = <BoxLarge />;
      break;
    default:
      boxIcon = <BoxMedium />;
  }

  return (
    <ListGroup.Item className="d-flex flex-wrap align-items-center justify-content-between mb-3 pb-4">
      <div className="d-flex flex-wrap align-items-center mb-3 mb-md-0">
        <div className="me-4">{boxIcon}</div>
        <div className="flex-fill">
          <h2 className="h5 mb-0">
            Συσκευασία{" "}
            {plan.transform_usage && plan.transform_usage.divide_by * 2}{" "}
            γευμάτων
          </h2>
          <p className="fs-5 mb-0">
            <Currency value={plan.amount / 100} /> ανά εβδομάδα
          </p>
          <small>
            Επόμενη ανανέωση στις {dateFormat(currentPeriodEnd * 1000)}
          </small>
        </div>
      </div>
      <div className="d-grid gap-2">
        <Button variant="primary" as={Anchor} href={id}>
          Επεξεργασία
        </Button>
        <OverlayTrigger
          placement="auto"
          show={error}
          onToggle={() => setError(false)}
          delay={5000}
          overlay={
            <Tooltip id="cancel-error-tooltip">
              Παρουσιάστηκε κάποιο πρόβλημα. Δοκιμάστε ξανά αργότερα.
            </Tooltip>
          }
        >
          <Button variant="outline-dark" onClick={() => handleCancelation(id)}>
            {loading ? <Spinner animation="grow" size="sm" /> : "Ακύρωση"}
          </Button>
        </OverlayTrigger>
      </div>
    </ListGroup.Item>
  );
};

export default PlanListItem;
