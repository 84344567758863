import React from "react";

import { ListGroup, Placeholder } from "react-bootstrap";

const PaymentMethodPlaceholder = () => {
  return (
    <ListGroup variant="flush">
      <ListGroup.Item className="d-flex flex-wrap align-items-center mb-3 pb-4">
        <Placeholder animation="glow">
          <Placeholder
            as="div"
            style={{
              width: "40px",
              height: "30px",
              marginRight: "1rem",
              backgroundColor: "var(--bs-gray-300)",
            }}
          />
        </Placeholder>
        <Placeholder animation="glow" className="flex-fill">
          <Placeholder
            as="div"
            className="rounded-2"
            style={{
              width: "70px",
              height: "30px",
              marginRight: "1rem",
              backgroundColor: "var(--bs-gray-300)",
            }}
          />
        </Placeholder>
        <Placeholder animation="glow">
          <Placeholder
            as="div"
            style={{
              width: "100px",
              height: "30px",
              marginRight: "1rem",
              backgroundColor: "var(--bs-gray-300)",
            }}
          />
        </Placeholder>
        <Placeholder animation="glow">
          <Placeholder
            as="div"
            style={{
              width: "30px",
              height: "30px",
              marginRight: "1rem",
              backgroundColor: "var(--bs-gray-300)",
            }}
          />
        </Placeholder>
      </ListGroup.Item>
      <ListGroup.Item className="d-flex flex-wrap align-items-center mb-3 pb-4">
        <Placeholder animation="glow">
          <Placeholder
            as="div"
            style={{
              width: "40px",
              height: "30px",
              marginRight: "1rem",
              backgroundColor: "var(--bs-gray-300)",
            }}
          />
        </Placeholder>
        <Placeholder animation="glow" className="flex-fill">
          <Placeholder
            as="div"
            className="rounded-2"
            style={{
              width: "70px",
              height: "30px",
              marginRight: "1rem",
              backgroundColor: "var(--bs-gray-300)",
            }}
          />
        </Placeholder>
        <Placeholder animation="glow">
          <Placeholder
            as="div"
            style={{
              width: "100px",
              height: "30px",
              marginRight: "1rem",
              backgroundColor: "var(--bs-gray-300)",
            }}
          />
        </Placeholder>
        <Placeholder animation="glow">
          <Placeholder
            as="div"
            style={{
              width: "30px",
              height: "30px",
              marginRight: "1rem",
              backgroundColor: "var(--bs-gray-300)",
            }}
          />
        </Placeholder>
      </ListGroup.Item>
      <ListGroup.Item className="d-flex flex-wrap align-items-center mb-3 pb-4">
        <Placeholder animation="glow">
          <Placeholder
            as="div"
            style={{
              width: "40px",
              height: "30px",
              marginRight: "1rem",
              backgroundColor: "var(--bs-gray-300)",
            }}
          />
        </Placeholder>
        <Placeholder animation="glow" className="flex-fill">
          <Placeholder
            as="div"
            className="rounded-2"
            style={{
              width: "70px",
              height: "30px",
              marginRight: "1rem",
              backgroundColor: "var(--bs-gray-300)",
            }}
          />
        </Placeholder>
        <Placeholder animation="glow">
          <Placeholder
            as="div"
            style={{
              width: "100px",
              height: "30px",
              marginRight: "1rem",
              backgroundColor: "var(--bs-gray-300)",
            }}
          />
        </Placeholder>
        <Placeholder animation="glow">
          <Placeholder
            as="div"
            style={{
              width: "30px",
              height: "30px",
              marginRight: "1rem",
              backgroundColor: "var(--bs-gray-300)",
            }}
          />
        </Placeholder>
      </ListGroup.Item>
    </ListGroup>
  );
};

export default PaymentMethodPlaceholder;
