import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import { Container } from "react-bootstrap";

const HeaderBg = ({ children }) => {
  return (
    <header
      className="mb-3 text-center bg-primary"
      style={{ "--bs-bg-opacity": "0.1" }}
    >
      <Container className="position-relative overflow-hidden py-4 py-lg-5">
        <StaticImage
          src="../../images/veggiespattern.png"
          className="position-absolute top-0 end-0 w-50 opacity-25 pe-none zindex--1"
          placeholder="tracedSVG"
          alt=""
        />
        <StaticImage
          src="../../images/veggiespattern.png"
          className="position-absolute bottom-0 start-0 w-50 opacity-25 pe-none zindex--1"
          placeholder="tracedSVG"
          alt=""
        />
        {children}
      </Container>
    </header>
  );
};

export default HeaderBg;
