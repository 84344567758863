import React from "react";
import { navigate } from "gatsby";

import { Button, Col, Container, Row } from "react-bootstrap";

import HeaderBg from "../blocks/HeaderBg";
import Anchor from "../elements/Anchor";
import { useCustomer } from "../../context/CustomerContext";
import { Account, BoxProduct, CreditCard, Task } from "../../helpers/Icons";

const Dashboard = () => {
  const { customer, handleLogout } = useCustomer();

  if (!customer.jwt) {
    navigate("/account/register/");
    return null;
  }

  return (
    <article>
      <HeaderBg>
        <h1 className="fw-light text-primary">Ο Λογαριασμός μου</h1>
        <p className="text-muted">
          {customer.name || customer.email}
          <br />
          <Button variant="link" onClick={handleLogout}>
            Έξοδος
          </Button>
        </p>
      </HeaderBg>
      <Container as="section" className="pt-5">
        <Row>
          <Col xs={6} md={3} className="mb-4">
            <Anchor
              href="/account/dashboard/profile/"
              className="d-flex flex-column align-items-center justify-content-center h-100 p-4 py-md-5 text-center link-unstyled border-1 shadow rounded-2"
            >
              <Account size={55} color="var(--bs-primary)" />
              <h2 className="h5 mt-3 fw-normal">Προφίλ</h2>
            </Anchor>
          </Col>
          <Col xs={6} md={3} className="mb-4">
            <Anchor
              href="/account/dashboard/plans/"
              className="d-flex flex-column align-items-center justify-content-center h-100 p-4 py-md-5 text-center link-unstyled border-1 shadow rounded-2"
            >
              <BoxProduct size={55} color="var(--bs-primary)" />
              <h2 className="h5 mt-3 fw-normal">Συνδρομές</h2>
            </Anchor>
          </Col>
          <Col xs={6} md={3} className="mb-4">
            <Anchor
              href="/account/dashboard/payment-methods/"
              className="d-flex flex-column align-items-center justify-content-center h-100 p-4 py-md-5 text-center link-unstyled border-1 shadow rounded-2"
            >
              <CreditCard size={55} color="var(--bs-primary)" />
              <h2 className="h5 mt-3 fw-normal">Τρόποι Πληρωμής</h2>
            </Anchor>
          </Col>
          <Col xs={6} md={3} className="mb-4">
            <Anchor
              href="/account/dashboard/history/"
              className="d-flex flex-column align-items-center justify-content-center h-100 p-4 py-md-5 text-center link-unstyled border-1 shadow rounded-2"
            >
              <Task size={55} color="var(--bs-primary)" />
              <h2 className="h5 mt-3 fw-normal">Ιστορικό</h2>
            </Anchor>
          </Col>
        </Row>
      </Container>
    </article>
  );
};

export default Dashboard;
