import React, { useState, useEffect } from "react";

import { Breadcrumb, Button, Container, ListGroup } from "react-bootstrap";

import HeaderBg from "../blocks/HeaderBg";
import Anchor from "../elements/Anchor";
import SubscriptionItemPlaceholder from "../elements/placeholders/SubscriptionItemPlaceholder";
import { useCustomer } from "../../context/CustomerContext";
import { functionsBase } from "../../../config/site-config";
import { useShop } from "../../context/ShopContext";
import PlanListItem from "../elements/PlanListItem";
import { Home } from "../../helpers/Icons";

const Plans = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { signUp } = useShop();
  const { customer, subscriptions, setSubscriptions } = useCustomer();

  useEffect(() => {
    setLoading(true);
    setError(false);

    fetch(`${functionsBase}/list-subscriptions`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        params: {
          customer: customer.id,
          limit: 100,
          status: "active",
        },
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.error) return setError(true);
        return setSubscriptions(res.list.data);
      })
      .catch(() => setError(true))
      .finally(() => setLoading(false));
  }, [customer.id, setSubscriptions]);

  const subscriptionsList = subscriptions.map((sub) => (
    <PlanListItem subscription={sub} key={sub.id} />
  ));

  return (
    <article>
      <HeaderBg>
        <h1 className="fw-light text-primary">Οι συνδρομές μου</h1>
        <p className="text-muted">
          Δείτε και επεξεργαστείτε όλες τις συνδρομές σας
        </p>
        <Breadcrumb className="d-flex justify-content-center">
          <Breadcrumb.Item
            linkAs={Anchor}
            href="/account/dashboard/"
            title="Λογαριασμός"
          >
            <Home size={15} /> Λογαριασμός
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Συνδρομές</Breadcrumb.Item>
        </Breadcrumb>
      </HeaderBg>
      <Container as="section" className="pt-5">
        {!loading && !error && (
          <ListGroup variant="flush">
            {subscriptions.length > 0 ? (
              subscriptionsList
            ) : (
              <p className="my-6 text-center">
                Δεν βρέθηκαν συνδρομές στον λογαριασμό σας.
                <br />
                <Button as={Anchor} href={signUp} className="mt-3">
                  ΕΓΓΡΑΦΗ
                </Button>
              </p>
            )}
          </ListGroup>
        )}
        {loading && <SubscriptionItemPlaceholder />}
        {error && (
          <p className="my-6 text-center">
            Παρουσιάστηκε κάποιο πρόβλημα. Δοκιμάστε ξάνα αργότερα.
            <br />
            <Button as={Anchor} href="/account/dashboard/" className="mt-3">
              Επιστροφή στον λογαριασμό
            </Button>
          </p>
        )}
      </Container>
    </article>
  );
};

export default Plans;
