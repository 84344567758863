import React, { useState } from "react";

import {
  Badge,
  Dropdown,
  ListGroup,
  OverlayTrigger,
  Tooltip,
  Spinner,
} from "react-bootstrap";

import CreditCardBrand from "./CreditCardBrand";
import { functionsBase } from "../../../config/site-config";
import { useCustomer } from "../../context/CustomerContext";
import { Info } from "../../helpers/Icons";

const CreditCardListItem = ({ method }) => {
  const [actionLoading, setActionLoading] = useState(false);
  const {
    customer,
    setCustomer,
    customerDefaultPayment,
    paymentMethods,
    setPaymentMethods,
  } = useCustomer();

  const isDefault = customerDefaultPayment === method.id;
  const { card } = method;

  const makeDefaultPayment = (id) => {
    setActionLoading(true);

    fetch(`${functionsBase}/update-customer`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        cus_id: customer.id,
        params: { invoice_settings: { default_payment_method: id } },
      }),
    })
      .then((res) => res.json())
      .then((res) => setCustomer(res.customer))
      .finally(() => setActionLoading(false));
  };

  const detachPaymentMethod = (id) => {
    setActionLoading(true);

    fetch(`${functionsBase}/detach-payment`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ id }),
    })
      .then((res) => res.json())
      .then((res) => {
        const payments = paymentMethods.filter(
          (pmethod) => pmethod.id !== res.method.id
        );
        setPaymentMethods(payments);
      })
      .finally(() => setActionLoading(false));
  };

  return (
    <ListGroup.Item
      key={method.id}
      className="d-flex align-items-center flex-wrap mb-3 pb-3"
    >
      <span className="me-2">
        <CreditCardBrand brand={card.brand} type="card" size={50} />
      </span>
      <span className="me-2">...{card.last4}</span>
      {isDefault && (
        <Badge className="me-2 order-1 order-sm-0 ms-2 ms-sm-0">
          ΠΡΟΕΠΙΛΟΓΗ
        </Badge>
      )}
      <div className="d-flex justify-content-end flex-fill">
        <span className="me-2">
          <small>Λήγει</small> {card.exp_month}/{card.exp_year}
        </span>
      </div>
      {isDefault ? (
        <OverlayTrigger
          placement="auto"
          overlay={
            <Tooltip>Η προεπιλεγμένη κάρτα δεν μπορεί να αφαιρεθεί.</Tooltip>
          }
        >
          <span className="p-2">
            <Info />
            <span className="visually-hidden">Info</span>
          </span>
        </OverlayTrigger>
      ) : (
        <Dropdown align="end">
          <Dropdown.Toggle
            variant="none"
            className="dropdown-toggle--noarrow px-2"
          >
            {actionLoading ? (
              <Spinner animation="grow" size="sm" variant="dark" />
            ) : (
              <>&bull;&bull;&bull;</>
            )}
            <span className="visually-hidden">Επιλογές</span>
          </Dropdown.Toggle>
          <Dropdown.Menu variant="dark">
            <>
              <Dropdown.Item
                onClick={() => {
                  if (actionLoading) return null;
                  return makeDefaultPayment(method.id);
                }}
              >
                Ορισμός ως προεπιλογή
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item
                className="text-danger"
                onClick={() => {
                  if (actionLoading) return null;
                  return detachPaymentMethod(method.id);
                }}
              >
                Αφαίρεση κάρτας
              </Dropdown.Item>
            </>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </ListGroup.Item>
  );
};

export default CreditCardListItem;
