import React from "react";

import { ListGroup, Placeholder } from "react-bootstrap";

const SubscriptionItemPlaceholder = () => {
  return (
    <ListGroup variant="flush">
      <ListGroup.Item className="d-flex flex-wrap align-items-center mb-3 pb-4">
        <Placeholder animation="glow">
          <Placeholder
            as="div"
            className="me-4 rounded-circle"
            style={{
              width: "50px",
              height: "50px",
              backgroundColor: "var(--bs-gray-300)",
            }}
          />
        </Placeholder>
        <Placeholder animation="glow" className="d-grid flex-fill me-3">
          <Placeholder
            as="div"
            className="rounded-2"
            style={{
              width: "30%",
              minWidth: "200px",
              height: "15px",
              marginBottom: "5px",
              backgroundColor: "var(--bs-gray-500)",
            }}
          />
          <Placeholder
            as="div"
            className="rounded-2"
            style={{
              width: "30%",
              minWidth: "200px",
              height: "15px",
              marginBottom: "5px",
              backgroundColor: "var(--bs-gray-300)",
            }}
          />
          <Placeholder
            as="div"
            className="rounded-2"
            style={{
              width: "25%",
              minWidth: "170px",
              height: "10px",
              minHeight: "10px",
              backgroundColor: "var(--bs-gray-300)",
            }}
          />
        </Placeholder>
        <Placeholder className="d-grid gap-2 mt-3 mt-sm-0" animation="glow">
          <Placeholder
            as="div"
            className="rounded-3"
            style={{
              width: "180px",
              height: "50px",
              backgroundColor: "var(--bs-gray-300)",
            }}
          />
          <Placeholder
            as="div"
            className="rounded-3"
            style={{
              width: "180px",
              height: "50px",
              backgroundColor: "rgba(255,255,255,0)",
              border: "1px solid var(--bs-gray-300)",
            }}
          />
        </Placeholder>
      </ListGroup.Item>
      <ListGroup.Item className="d-flex flex-wrap align-items-center mb-3 pb-4">
        <Placeholder animation="glow">
          <Placeholder
            as="div"
            className="me-4 rounded-circle"
            style={{
              width: "50px",
              height: "50px",
              backgroundColor: "var(--bs-gray-300)",
            }}
          />
        </Placeholder>
        <Placeholder animation="glow" className="d-grid flex-fill me-3">
          <Placeholder
            as="div"
            className="rounded-2"
            style={{
              width: "30%",
              minWidth: "200px",
              height: "15px",
              marginBottom: "5px",
              backgroundColor: "var(--bs-gray-500)",
            }}
          />
          <Placeholder
            as="div"
            className="rounded-2"
            style={{
              width: "30%",
              minWidth: "200px",
              height: "15px",
              marginBottom: "5px",
              backgroundColor: "var(--bs-gray-300)",
            }}
          />
          <Placeholder
            as="div"
            className="rounded-2"
            style={{
              width: "25%",
              minWidth: "170px",
              height: "10px",
              minHeight: "10px",
              backgroundColor: "var(--bs-gray-300)",
            }}
          />
        </Placeholder>
        <Placeholder className="d-grid gap-2 mt-3 mt-sm-0" animation="glow">
          <Placeholder
            as="div"
            className="rounded-3"
            style={{
              width: "180px",
              height: "50px",
              backgroundColor: "var(--bs-gray-300)",
            }}
          />
          <Placeholder
            as="div"
            className="rounded-3"
            style={{
              width: "180px",
              height: "50px",
              backgroundColor: "rgba(255,255,255,0)",
              border: "1px solid var(--bs-gray-300)",
            }}
          />
        </Placeholder>
      </ListGroup.Item>
      <ListGroup.Item className="d-flex flex-wrap align-items-center mb-3 pb-4">
        <Placeholder animation="glow">
          <Placeholder
            as="div"
            className="me-4 rounded-circle"
            style={{
              width: "50px",
              height: "50px",
              backgroundColor: "var(--bs-gray-300)",
            }}
          />
        </Placeholder>
        <Placeholder animation="glow" className="d-grid flex-fill me-3">
          <Placeholder
            as="div"
            className="rounded-2"
            style={{
              width: "30%",
              minWidth: "200px",
              height: "15px",
              marginBottom: "5px",
              backgroundColor: "var(--bs-gray-500)",
            }}
          />
          <Placeholder
            as="div"
            className="rounded-2"
            style={{
              width: "30%",
              minWidth: "200px",
              height: "15px",
              marginBottom: "5px",
              backgroundColor: "var(--bs-gray-300)",
            }}
          />
          <Placeholder
            as="div"
            className="rounded-2"
            style={{
              width: "25%",
              minWidth: "170px",
              height: "10px",
              minHeight: "10px",
              backgroundColor: "var(--bs-gray-300)",
            }}
          />
        </Placeholder>
        <Placeholder className="d-grid gap-2 mt-3 mt-sm-0" animation="glow">
          <Placeholder
            as="div"
            className="rounded-3"
            style={{
              width: "180px",
              height: "50px",
              backgroundColor: "var(--bs-gray-300)",
            }}
          />
          <Placeholder
            as="div"
            className="rounded-3"
            style={{
              width: "180px",
              height: "50px",
              backgroundColor: "rgba(255,255,255,0)",
              border: "1px solid var(--bs-gray-300)",
            }}
          />
        </Placeholder>
      </ListGroup.Item>
    </ListGroup>
  );
};

export default SubscriptionItemPlaceholder;
