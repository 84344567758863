import React from "react";
import { navigate } from "gatsby";

import { isBrowser } from "../../helpers/checkEnvironment";
import { useCustomer } from "../../context/CustomerContext";

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  if (!isBrowser) return null;
  const { isLoggedIn } = useCustomer();

  if (!isLoggedIn() && location.pathname !== "/account/login/") {
    navigate("/account/login/", { replace: true });
    return null;
  }

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Component {...rest} />;
};

export default PrivateRoute;
