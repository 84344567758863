import React from "react";
import {
  Amex,
  AmexCard,
  Mastercard,
  MastercardCard,
  Visa,
  VisaCard,
} from "../../helpers/Icons";

const CreditCardBrand = ({ brand, type = "logo", size = 60 }) => {
  let cardIcon = null;

  switch (brand) {
    case "visa":
      cardIcon =
        type === "card" ? <VisaCard size={size} /> : <Visa size={size} />;
      break;
    case "mastercard":
      cardIcon =
        type === "card" ? (
          <MastercardCard size={size} />
        ) : (
          <Mastercard size={size} />
        );
      break;
    case "amex":
      cardIcon =
        type === "card" ? <AmexCard size={size} /> : <Amex size={size} />;
      break;
    default:
      cardIcon = null;
  }

  return (
    <>
      {cardIcon}
      <span
        // eslint-disable-next-line prettier/prettier
        className={`py-1 px-2 rounded-2 text-white bg-dark ${cardIcon ? "visually-hidden" : ""}`}
      >
        {brand}
      </span>
    </>
  );
};

export default CreditCardBrand;
