import React from "react";
// eslint-disable-next-line import/no-unresolved
import { Router } from "@reach/router";

import Layout from "../components/common/Layout";
import PrivateRoute from "../components/common/PrivateRoute";
import Login from "../components/blocks/Login";
import Register from "../components/blocks/Register";
import Dashboard from "../components/client-only/Dashboard";
import Plans from "../components/client-only/Plans";
import PlanDetails from "../components/client-only/PlanDetails";
import PaymentMethods from "../components/client-only/PaymentMethods";
import ProfileInformations from "../components/client-only/ProfileInformations";
import History from "../components/client-only/History";

const Account = () => (
  <Layout>
    <Router basepath="/account/">
      <Login path="/login/" default />
      <Register path="/register/" />
      <PrivateRoute path="/dashboard/" component={Dashboard} />
      <PrivateRoute
        path="/dashboard/profile/"
        component={ProfileInformations}
      />
      <PrivateRoute path="/dashboard/plans/" component={Plans} />
      <PrivateRoute path="/dashboard/plans/:planid/" component={PlanDetails} />
      <PrivateRoute
        path="/dashboard/payment-methods/"
        component={PaymentMethods}
      />
      <PrivateRoute path="/dashboard/history/" component={History} />
    </Router>
  </Layout>
);

export default Account;
