import React from "react";

import { Col, Row, Placeholder } from "react-bootstrap";

const PlanDetailsPlaceholder = () => {
  const cardStyles = {
    height: "160px",
    backgroundColor: "var(--bs-gray-300)",
  };
  const headerStyles = {
    height: "20px",
  };
  const pickerStyles = {
    height: "40px",
    backgroundColor: "var(--bs-gray-300)",
  };
  const productLineStyles = {
    height: "100px",
    backgroundColor: "var(--bs-gray-300)",
  };

  return (
    <Row>
      <Placeholder
        as={Col}
        md={4}
        className="d-grid gap-4 mb-5"
        animation="glow"
      >
        <Placeholder xs={12} className="rounded" style={cardStyles} />
        <Placeholder xs={12} className="rounded" style={cardStyles} />
        <Placeholder xs={12} className="rounded" style={cardStyles} />
      </Placeholder>
      <Col md={8}>
        <Row className="mb-5">
          <Placeholder animation="glow" className="mb-2">
            <Placeholder xs={4} style={headerStyles} />
          </Placeholder>
          <Placeholder as={Col} md={6} animation="glow">
            <Placeholder xs={12} className="rounded" style={pickerStyles} />
          </Placeholder>
          <Placeholder as={Col} md={6} animation="glow">
            <Placeholder xs={12} className="rounded" style={pickerStyles} />
          </Placeholder>
        </Row>
        <Row className="mb-4">
          <Placeholder animation="glow" className="mb-2">
            <Placeholder xs={4} style={headerStyles} />
          </Placeholder>
          <Placeholder animation="glow" className="mb-2">
            <Placeholder
              xs={12}
              className="rounded"
              style={productLineStyles}
            />
          </Placeholder>
          <Placeholder animation="glow" className="mb-2">
            <Placeholder
              xs={12}
              className="rounded"
              style={productLineStyles}
            />
          </Placeholder>
          <Placeholder animation="glow" className="mb-2">
            <Placeholder
              xs={12}
              className="rounded"
              style={productLineStyles}
            />
          </Placeholder>
          <Placeholder animation="glow" className="mb-2">
            <Placeholder
              xs={12}
              className="rounded"
              style={productLineStyles}
            />
          </Placeholder>
        </Row>
      </Col>
    </Row>
  );
};

export default PlanDetailsPlaceholder;
