import React, { useState, useEffect } from "react";
import { navigate } from "gatsby";
import { createClient } from "@prismicio/client";

import {
  Alert,
  Breadcrumb,
  Button,
  Card,
  Col,
  Container,
  Modal,
  OverlayTrigger,
  Row,
  Spinner,
  Tooltip,
} from "react-bootstrap";

import Anchor from "../elements/Anchor";
import HeaderBg from "../blocks/HeaderBg";
import DatePicker from "../elements/DatePicker";
import TimePicker from "../elements/TimePicker";
import ProductListItem from "../elements/ProductListItem";
import PlanDetailsPlaceholder from "../elements/placeholders/PlanDetailsPlaceholder";
import { useCustomer } from "../../context/CustomerContext";
import { functionsBase } from "../../../config/site-config";
import { Currency, dateFormat } from "../../helpers";
import { createItemsMetadata, parseItemsMetadata } from "../../helpers/helpers";
import {
  BoxMedium,
  CreditCard,
  ForkKnifeCircle,
  Home,
  Pen,
  PlusCircle,
  Warning,
} from "../../helpers/Icons";

import "../../styles/pages/plan-details.scss";
import "../../styles/components/elements/product-list-item.scss";

// {i:"five-spice-chicken", q:2},{i:"chicken-quesadillas", q:1},{i:"mac-n-cheese", q:1}
const PlanDetails = ({ planid }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [cancelLoading, setCancelLoading] = useState(false);
  const [cancelError, setCancelError] = useState(false);
  const [disableActions, setDisableActions] = useState(true);

  const [subscription, setSubscription] = useState(null);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [productCount, setProductCount] = useState(0);
  const [allProducts, setAllProducts] = useState([]);
  const [showProductsModal, setShowProductsModal] = useState(false);

  const { customer } = useCustomer();

  const productGraphQuery = `{
    product {
      categories {
        category {
          ... on category {
            title
          }
        }
      }
      cook_time
      description
      difficulty_level
      media
      preparation_time
      servings
      title
    }
  }`;

  useEffect(() => {
    setLoading(true);
    setError(false);

    const prismic = createClient(
      `https://${process.env.GATSBY_PRISMIC_REPO_NAME}.prismic.io/api/v2`
    );

    // Get the subscription details
    fetch(`${functionsBase}/retrieve-subscription`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        subscriptionId: planid,
      }),
    })
      .then((res) => res.json())
      .then(async (res) => {
        if (res.error) return setError(true);
        if (res.subscription) {
          setSubscription(res.subscription);

          if (
            !res.subscription.metadata.items &&
            res.subscription.metadata.items === ""
          )
            return null;

          try {
            // Get the products from prismic included in subscription
            const subscriptionItems = parseItemsMetadata(
              res.subscription.metadata.items
            );
            const productData = await Promise.all(
              subscriptionItems.map(async (item) => {
                const itemData = await prismic.getByUID("product", item.i, {
                  graphQuery: productGraphQuery,
                });
                itemData.data.quantity = item.q;
                return itemData;
              })
            );

            // Count the quantity of all products
            let count = 0;
            productData.forEach((product) => {
              count += product.data.quantity;
            });
            setProductCount(count);

            setSelectedProducts(productData);
          } catch (err) {
            return null;
          }
        }
        return null;
      })
      .catch(() => setError(true))
      .finally(() => {
        setLoading(false);
        setDisableActions(false);
      });
  }, [planid, productGraphQuery]);

  // Update subscription items
  const updateSubscriptionItems = (items, setItemLoading, setItemError) => {
    const data = {
      subscriptionId: subscription.id,
      params: {
        metadata: {
          items: createItemsMetadata(items),
        },
      },
    };

    fetch(`${functionsBase}/update-subscription`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.subscription) {
          let count = 0;
          items.forEach((product) => {
            count += product.data.quantity;
          });
          setProductCount(count);

          setSelectedProducts([...items]);
          setItemLoading(false);
          setDisableActions(false);
        }
        if (res.error) setItemError(true);
      })
      .catch(() => {
        setItemError(true);
        setItemLoading(false);
        setDisableActions(false);
      });
  };

  // Remove an item from plan
  const removeItem = (item, setItemLoading, setItemError) => {
    setItemLoading(true);
    setItemError(false);
    setDisableActions(true);

    const products = selectedProducts.filter(
      (product) => product.uid !== item.uid
    );

    updateSubscriptionItems(products, setItemLoading, setItemError);
  };

  // Add an item to plan
  const addItem = (product, setItemLoading, setItemError) => {
    setItemLoading(true);
    setItemError(false);
    setDisableActions(true);

    const products = [...selectedProducts];

    const productPos = selectedProducts.findIndex((i) => i.id === product.id);
    if (productPos < 0) {
      const item = product;
      item.data.quantity = 1;
      products.push(item);
    } else {
      products[productPos].data.quantity += 1;
    }

    updateSubscriptionItems(products, setItemLoading, setItemError);
  };

  // Handle cancel plan button
  const handleCancelation = () => {
    setCancelLoading(true);
    setCancelError(false);

    fetch(`${functionsBase}/cancel-subscription`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        subscriptionId: subscription.id,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.error) return setCancelError(true);
        if (res.deleted) {
          navigate("/account/dashboard/plans/");
        }
        return null;
      })
      .catch(() => {
        setCancelError(true);
        setCancelLoading(false);
      });
  };

  // Query all products if needed and open modal
  const showAddItemsModal = async () => {
    if (allProducts.length < 1) {
      const allPrismicProducts = JSON.parse(
        window.sessionStorage.getItem("allProducts")
      );

      if (!allPrismicProducts) {
        const prismic = createClient(
          `https://${process.env.GATSBY_PRISMIC_REPO_NAME}.prismic.io/api/v2`
        );

        const products = await prismic.getAllByType("product", {
          graphQuery: productGraphQuery,
        });

        window.sessionStorage.setItem("allProducts", JSON.stringify(products));
        setAllProducts(products);
      } else {
        setAllProducts(allPrismicProducts);
      }
    }

    setShowProductsModal(true);
  };

  // Render the list of selected products
  const renderSelectedProducts = selectedProducts.map((product) => (
    <ProductListItem
      key={product.uid}
      product={product}
      type="remove"
      disabled={disableActions}
      clickHandler={removeItem}
      className="border"
    />
  ));

  return (
    <article className="plan-details">
      <HeaderBg>
        <h1 className="fw-light text-primary">Επεξεργασία συνδρομής</h1>
        <p className="text-muted">
          {subscription?.plan?.transform_usage?.divide_by && (
            <>
              Συσκευασία {subscription.plan.transform_usage.divide_by * 2}{" "}
              γευμάτων
            </>
          )}
        </p>
        <Breadcrumb className="d-flex justify-content-center">
          <Breadcrumb.Item
            linkAs={Anchor}
            href="/account/dashboard/"
            title="Λογαριασμός"
          >
            <Home size={15} /> Λογαριασμός
          </Breadcrumb.Item>
          <Breadcrumb.Item
            linkAs={Anchor}
            href="/account/dashboard/plans/"
            title="Συνδρομές"
          >
            Συνδρομές
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Συσκευασία</Breadcrumb.Item>
        </Breadcrumb>
      </HeaderBg>
      <Container className="mt-5">
        {loading && <PlanDetailsPlaceholder />}
        {error && (
          <p className="my-6 text-center">
            Παρουσιάστηκε κάποιο πρόβλημα. Δοκιμάστε ξάνα αργότερα.
            <br />
            <Button
              as={Anchor}
              href="/account/dashboard/plans/"
              className="mt-3"
            >
              Επιστροφή στις συνδρομές
            </Button>
          </p>
        )}
        {!loading && !error && subscription && (
          <Row>
            <Col md={4} className="d-grid align-content-start gap-4 mb-5">
              <Card className="shadow-sm rounded overflow-hidden">
                <Card.Header as="h2" className="h6 position-relative p-4">
                  ΣΤΟΙΧΕΙΑ ΧΡΕΩΣΗΣ
                  <div className="plan-details__card-icon">
                    <CreditCard size={110} />
                  </div>
                </Card.Header>
                <Card.Body className="p-4">
                  <Card.Text as="div" className="d-grid gap-2">
                    <div className="d-flex justify-content-between flex-wrap">
                      <p className="flex-fill mb-0 me-5">
                        <span className="small text-primary">Ποσό</span>
                        <br />
                        <Currency value={subscription.plan.amount / 100} />
                      </p>
                      <p className="flex-fill mb-0 me-5">
                        <span className="small text-primary">Έκπτωση</span>
                        <br />-{subscription.plan.metadata.discount}%
                      </p>
                      <p className="flex-fill mb-0">
                        <span className="small text-primary">Ανανέωση</span>
                        <br />
                        ανά εβδομάδα
                      </p>
                    </div>
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card className="shadow-sm rounded overflow-hidden">
                <Card.Header as="h2" className="h6 position-relative p-4">
                  ΣΤΟΙΧΕΙΑ ΣΥΝΔΡΟΜΗΣ
                  <div className="plan-details__card-icon">
                    <BoxMedium size={110} />
                  </div>
                </Card.Header>
                <Card.Body className="p-4">
                  <Card.Text as="div" className="d-grid gap-2">
                    <div className="d-flex justify-content-between flex-wrap">
                      <p className="flex-fill mb-0 me-5">
                        <span className="small text-primary">
                          Πρώτη εγγραφή
                        </span>
                        <br />
                        {dateFormat(subscription.created * 1000)}
                      </p>
                      <p className="flex-fill mb-0">
                        <span className="small text-primary">
                          Τρέχουσα Περίοδος
                        </span>
                        <br />
                        {dateFormat(
                          subscription.current_period_start * 1000
                        )} -{" "}
                        {dateFormat(subscription.current_period_end * 1000)}
                      </p>
                    </div>
                  </Card.Text>
                </Card.Body>
              </Card>
              <Card className="shadow-sm rounded overflow-hidden">
                <Card.Header as="h2" className="h6 position-relative p-4">
                  ΣΤΟΙΧΕΙΑ ΠΑΚΕΤΟΥ
                  <div className="plan-details__card-icon">
                    <ForkKnifeCircle size={100} />
                  </div>
                </Card.Header>
                <Card.Body className="p-4">
                  <Card.Text as="div" className="d-grid gap-2">
                    <div className="d-flex justify-content-between flex-wrap">
                      <p className="flex-fill mb-0 me-5">
                        <span className="small text-primary">Συνταγές</span>
                        <br />
                        {selectedProducts.length}
                      </p>
                      <p className="flex-fill mb-0 me-5">
                        <span className="small text-primary">Γεύματα</span>
                        <br />
                        {productCount * 2}
                      </p>
                    </div>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={8}>
              <Row as="section" className="mb-5">
                <h2 className="h5">Λεπτομέρειες παράδοσης</h2>
                {(customer.shipping?.address?.line1 || customer.address) && (
                  <Col xs={12} className="mb-2">
                    <Anchor
                      href="/account/dashboard/profile/"
                      className="d-flex align-items-baseline text-decoration-none"
                      title="Επεξεργασία διεύθυνσης"
                    >
                      <span className="me-1">
                        {customer.shipping?.address?.line1 ||
                          customer.address.line1}
                        ,{" "}
                        {customer.shipping?.address?.city ||
                          customer.address.city}
                        ,{" "}
                        {customer.shipping?.address?.postal_code ||
                          customer.address.postal_code}{" "}
                      </span>
                      <Pen size={14} />
                    </Anchor>
                  </Col>
                )}
                <Col md={6}>
                  <DatePicker
                    date={new Date(Number(subscription.metadata.internal_date))}
                    subscription={subscription.id}
                  />
                </Col>
                <Col md={6}>
                  <TimePicker
                    time={subscription.metadata.time}
                    subscription={subscription.id}
                  />
                </Col>
              </Row>
              <Row as="section" className="mb-4">
                <h2 className="h5">Επιλεγμένες συνταγές</h2>
                <Col xs={12}>
                  {renderSelectedProducts}
                  {productCount === 0 && (
                    <Alert variant="danger">
                      <div className="text-center">
                        <Warning size={36} />
                        <h2 className="h5 mt-1">ΠΡΟΣΟΧΗ!</h2>
                        <p className="mb-4">
                          Δεν υπάρχουν επιλεγμένα γεύματα, αλλά η συνδρομή είναι
                          ακόμη <strong>ενεργή</strong>.
                        </p>
                        <hr />
                        <p className="mb-0">
                          <Button
                            variant="primary"
                            className="m-1"
                            onClick={showAddItemsModal}
                            onKeyPress={(e) => {
                              if (e.key === "Enter") showAddItemsModal();
                            }}
                          >
                            Προσθήκη συνταγών
                          </Button>
                          <OverlayTrigger
                            placement="auto"
                            show={cancelError}
                            onToggle={() => setCancelError(false)}
                            delay={5000}
                            overlay={
                              <Tooltip id="cancel-sub-error-tooltip">
                                Παρουσιάστηκε κάποιο πρόβλημα. Δοκιμάστε ξανά
                                αργότερα.
                              </Tooltip>
                            }
                          >
                            <Button
                              variant="outline-danger"
                              className="m-1"
                              onClick={handleCancelation}
                              onKeyPress={(e) => {
                                if (e.key === "Enter") handleCancelation();
                              }}
                              disabled={cancelLoading}
                            >
                              {cancelLoading ? (
                                <Spinner animation="grow" size="sm" />
                              ) : (
                                "Ακύρωση συνδρομής"
                              )}
                            </Button>
                          </OverlayTrigger>
                        </p>
                      </div>
                    </Alert>
                  )}
                  {productCount < subscription?.plan?.transform_usage?.divide_by && ( // eslint-disable-line prettier/prettier
                    <div className="d-flex mb-2 p-2 border text-primary">
                      <button
                        type="button"
                        className="d-flex align-items-center justify-content-center"
                        onClick={showAddItemsModal}
                      >
                        <div className="product-list-item__figure d-flex align-items-center justify-content-center bg-light">
                          <PlusCircle size={30} />
                        </div>
                        <div className="text-start">
                          <p className="mb-0 fw-bold">Προσθήκη συνταγής</p>
                          <small className="text-muted">
                            Μπορείς να προσθέσεις{" "}
                            {subscription.plan.transform_usage.divide_by -
                              productCount}
                            {subscription.plan.transform_usage.divide_by - productCount > 1 // eslint-disable-line prettier/prettier
                              ? " συνταγές"
                              : " συνταγή"}{" "}
                            ακόμη.
                          </small>
                        </div>
                      </button>
                    </div>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      </Container>
      <Modal
        show={
          showProductsModal &&
          productCount < subscription?.plan?.transform_usage?.divide_by
        }
        onHide={() => setShowProductsModal(false)}
        aria-labelledby="modal.newitems"
        centered
        scrollable
        size="lg"
        contentClassName="border-primary"
        fullscreen="sm-down"
      >
        <Modal.Header
          closeButton
          closeLabel="Κλείσιμο"
          closeVariant="white"
          className="text-white bg-primary"
        >
          <Modal.Title as="header" id="modal.newitems">
            <h2 className="h5 mb-0">Προσθήκη συνταγής</h2>
            {subscription?.plan?.transform_usage?.divide_by && (
              <p className="mb-0">
                Απομένουν:{" "}
                {subscription.plan.transform_usage.divide_by - productCount}
              </p>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {allProducts.map((product) => (
            <ProductListItem
              key={product.uid}
              product={product}
              type="add"
              className="pb-2 border-bottom"
              disabled={disableActions}
              clickHandler={addItem}
            />
          ))}
        </Modal.Body>
      </Modal>
    </article>
  );
};

export default PlanDetails;
