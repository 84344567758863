import React, { useState } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { RichText } from "prismic-reactjs";

import {
  Accordion,
  Badge,
  Button,
  OverlayTrigger,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import { useAccordionButton } from "react-bootstrap/AccordionButton";

import RecipeStats from "./RecipeStats";
import Anchor from "./Anchor";
import { ChevronDown, Close, Plus } from "../../helpers/Icons";

import "../../styles/components/elements/product-list-item.scss";

const DetailsToggle = ({ children, eventKey }) => {
  const clickHandler = useAccordionButton(eventKey);

  return (
    <button
      type="button"
      className="d-block text-primary"
      onClick={clickHandler}
      onKeyPress={(e) => {
        if (e.key === "Enter") clickHandler();
      }}
    >
      {children}
    </button>
  );
};

const ProductListItem = ({
  product,
  type,
  className,
  disabled,
  clickHandler,
}) => {
  if (!product) return null;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const { title, media, description, quantity, servings, categories } =
    product.data;

  const typeData = {
    remove: {
      icon: <Close size={16} />,
      text: "Αφαίρεση",
      color: "danger",
    },
    add: {
      icon: <Plus size={16} />,
      text: "Προσθήκη",
      color: "primary",
    },
  };

  return (
    <Accordion as="article" className={`mb-2 p-2 ${className || ""}`}>
      <div className="d-flex align-items-center">
        {media?.length > 0 && media[0].image && (
          <figure className="product-list-item__figure bg-light">
            {media[0].image.gatsbyImageData ? (
              <GatsbyImage
                image={media[0].image.gatsbyImageData}
                alt={media[0].image.alt || title || ""}
                className="bg-light"
              />
            ) : (
              media[0].image.url && (
                <img
                  src={`${media[0].image.url}&w=100`}
                  alt={media[0].image.alt || title || ""}
                />
              )
            )}
          </figure>
        )}
        <div className="flex-fill">
          <h2 className="h6 mb-0 mw-400">{title}</h2>
          <DetailsToggle eventKey={product.uid}>
            Πληροφορίες <ChevronDown size={10} color="currentColor" />
          </DetailsToggle>
          <small className="text-muted">
            Γεύματα:{" "}
            {quantity && type !== "add" ? quantity * servings : servings}
          </small>
        </div>
        <OverlayTrigger
          placement="auto"
          show={error}
          onToggle={() => setError(false)}
          delay={5000}
          overlay={
            <Tooltip id="remove-error-tooltip">
              Παρουσιάστηκε κάποιο πρόβλημα. Δοκιμάστε ξανά αργότερα.
            </Tooltip>
          }
        >
          <Button
            variant="link"
            className={`my-3 px-3 text-${typeData[type].color} text-decoration-none shadow-none`}
            disabled={disabled || loading}
            title={typeData[type].text}
            onClick={() => {
              if (typeof clickHandler === "function")
                clickHandler(product, setLoading, setError);
            }}
            onKeyPress={(e) => {
              if (e.key === "Enter")
                if (typeof clickHandler === "function")
                  clickHandler(product, setLoading, setError);
            }}
          >
            {loading ? (
              <Spinner animation="grow" size="sm" />
            ) : (
              <>
                {typeData[type].icon}
                <span className="visually-hidden">{typeData[type].text}</span>
              </>
            )}
          </Button>
        </OverlayTrigger>
      </div>
      <Accordion.Collapse eventKey={product.uid}>
        <div className="mt-3 py-2 border-top">
          <div className="d-flex justify-content-sm-center flex-wrap mb-2">
            <RecipeStats data={product.data} />
          </div>
          <div className="text-center">
            {categories?.length > 0 &&
              categories.map((item) => (
                <Badge
                  pill
                  className="me-1 mb-1 text-uppercase"
                  key={item.category.uid}
                >
                  {item.category.data.title}
                </Badge>
              ))}
            <RichText render={description} />
            <Button
              as={Anchor}
              variant="outline-primary"
              size="sm"
              href={`/products/${product.uid}`}
              title={title}
            >
              Δες ολόκληρη την συνταγή
            </Button>
          </div>
        </div>
      </Accordion.Collapse>
    </Accordion>
  );
};

export default ProductListItem;
