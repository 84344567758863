import React, { useState } from "react";
import { navigate } from "gatsby";

import { Button, Container, Form, Modal, Spinner } from "react-bootstrap";

import HeaderBg from "./HeaderBg";
import Anchor from "../elements/Anchor";
import { useCustomer } from "../../context/CustomerContext";
import { useShop } from "../../context/ShopContext";
import { ArrowDownBig } from "../../helpers/Icons";

const Login = () => {
  const { handleLogin, isLoggedIn } = useCustomer();

  if (isLoggedIn()) {
    navigate("/account/dashboard/");
    return null;
  }

  const [message, setMessage] = useState(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [enableForgot, setEnableForgot] = useState(false);
  const [loading, setLoading] = useState(false);

  const { signUp } = useShop();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage(null);

    if (email === "") return setMessage("Το email είναι υποχρεωτικό.");
    if (password === "") return setMessage("Ο κωδικός είναι υποχρεωτικός.");

    setLoading(true);
    const loggedIn = await handleLogin({ email, password });

    if (loggedIn) {
      navigate("/account/dashboard/");
    } else {
      setMessage("Το email ή ο κωδικός είναι λάθος.");
    }

    setLoading(false);
    return null;
  };

  const handleForgotModal = () => {
    setEnableForgot(true);
  };

  return (
    <article>
      <HeaderBg>
        <h2 className="fw-light text-primary">Είσοδος λογαριασμού</h2>
        <p className="mb-0 text-muted">
          Συνδεθείτε στον λογαριασμό σας με το email και τον κωδικό σας.
        </p>
        <p className="text-muted">
          Δεν έχετε λογαριασμό? <Anchor href={signUp}>Κάντε εγγραφή</Anchor>
        </p>
      </HeaderBg>
      <div className="mb-3 text-center" aria-hidden="true">
        <ArrowDownBig size="58" />
      </div>
      <Container className="pb-5 pb-lg-7">
        <Form className="mw-400 mx-auto">
          <Form.Group className="mb-3" controlId="loginForm.email">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              placeholder="Διεύθυνση email"
              value={email}
              onChange={(e) => {
                setMessage(null);
                setEmail(e.target.value);
              }}
            />
          </Form.Group>
          <Form.Group controlId="loginForm.password">
            <Form.Label>Κωδικός</Form.Label>
            <Form.Control
              type="password"
              placeholder="Κωδικός πρόσβασης"
              value={password}
              onChange={(e) => {
                setMessage(null);
                setPassword(e.target.value);
              }}
            />
          </Form.Group>
          <div className="mb-3 text-end">
            <button
              type="button"
              className="small text-muted"
              onClick={handleForgotModal}
              onKeyPress={(e) => {
                if (e.key === "Enter") handleForgotModal();
              }}
            >
              Ξέχασα τον κωδικό μου
            </button>
          </div>
          {message && <p className="text-danger text-center">{message}</p>}
          <div className="mt-4 text-center">
            <Button
              variant="primary"
              type="submit"
              onClick={(e) => handleSubmit(e)}
              disabled={loading}
            >
              {loading ? (
                <>
                  <Spinner
                    animation="grow"
                    size="sm"
                    variant="light"
                    className="me-1"
                  />
                  Σύνδεση...
                </>
              ) : (
                "Σύνδεση"
              )}
            </Button>
          </div>
        </Form>
      </Container>
      <Modal
        show={enableForgot}
        onHide={() => setEnableForgot(false)}
        aria-labelledby="modal.forgotPassword"
        contentClassName="border-primary"
        fullscreen="sm-down"
        centered
      >
        <Modal.Header
          className="text-white bg-primary"
          closeButton
          closeLabel="Κλείσιμο"
          closeVariant="white"
        >
          <Modal.Title id="modal.forgotPassword">Επαναφορά κωδικού</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="forgotForm.email">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" placeholder="Διεύθυνση email" />
            </Form.Group>
            <Form.Group className="text-center">
              <Button type="submit">Επαναφορά</Button>
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>
    </article>
  );
};

export default Login;
