import React, { useState } from "react";
import { navigate } from "gatsby";

import { Alert, Button, Container, Form, Spinner } from "react-bootstrap";

import HeaderBg from "./HeaderBg";
import { useCustomer } from "../../context/CustomerContext";
import { useShop } from "../../context/ShopContext";

const Register = () => {
  const { customer, handleRegistration } = useCustomer();
  const { signUp } = useShop();

  const [email] = useState(customer?.email || "");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  if (!customer || !customer?.email) {
    navigate(signUp, { replace: true });
    return null;
  }

  if (customer && customer.jwt) {
    navigate("/account/dashboard/", { replace: true });
    return null;
  }

  const handleSubmit = async ({ e, email: _email, password: _password }) => {
    e.preventDefault();

    if (password.length < 6)
      return setError({
        message:
          "Ο κωδικός είναι πολύ μικρός, πρέπει να είναι τουλάχιστον 6 χαρακτήρες.",
      });

    setLoading(true);
    const registration = await handleRegistration({
      email: _email,
      password: _password,
    });
    setLoading(false);

    if (registration?.error) return setError(registration.error);

    return null;
  };

  return (
    <article>
      <HeaderBg>
        <h1 className="fw-light text-primary">Ολοκλήρωση εγγραφής</h1>
      </HeaderBg>
      <Container as="section" className="pt-5">
        <Form
          className="mw-600 mx-auto"
          onSubmit={async (e) => {
            await handleSubmit({ e, email, password });
          }}
        >
          <Form.Group className="mb-3" controlId="loginForm.email">
            <Form.Label>Ορίστε έναν κωδικό για τον λογαριασμό σας</Form.Label>
            <Form.Control
              type="password"
              placeholder="Κωδικός πρόσβασης"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                setError(null);
              }}
            />
            <Form.Text>
              &bull; Ο κωδικός πρέπει να αποτελείται από τουλάχιστον 6
              χαρακτήρες.
            </Form.Text>
          </Form.Group>
          <Button type="submit">
            {loading && (
              <Spinner
                animation="grow"
                size="sm"
                variant="light"
                className="me-1"
              />
            )}
            Ολοκλήρωση
          </Button>
          {error && (
            <Alert variant="danger" className="mt-3">
              {error.message}
            </Alert>
          )}
        </Form>
      </Container>
    </article>
  );
};

export default Register;
