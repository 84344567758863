import React, { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";

import { Breadcrumb, Button, Container, ListGroup } from "react-bootstrap";

import HeaderBg from "../blocks/HeaderBg";
import Anchor from "../elements/Anchor";
import CreditCard from "../elements/CreditCard";
import CreditCardListItem from "../elements/CreditCardListItem";
import PaymentMethodPlaceholder from "../elements/placeholders/PaymentMethodPlaceholder";
import { useShop } from "../../context/ShopContext";
import { functionsBase, siteLang } from "../../../config/site-config";
import { useCustomer } from "../../context/CustomerContext";
import { Home } from "../../helpers/Icons";

const PaymentMethods = () => {
  const { stripePromise } = useShop();
  const {
    customer,
    customerDefaultPayment,
    paymentMethods,
    setPaymentMethods,
  } = useCustomer();

  const [defaultPayment, setDefaultPayment] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    setLoading(true);
    setError(false);

    fetch(`${functionsBase}/customer-payment-methods`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        cus_id: customer.id,
        params: {
          limit: 100,
        },
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.error) return setError(true);
        return setPaymentMethods(res.paymentMethods.data);
      })
      .catch(() => setError(true))
      .finally(() => setLoading(false));
  }, [customer.id, setPaymentMethods]);

  useEffect(() => {
    const defaultSource = paymentMethods.filter(
      (method) => method.id === customerDefaultPayment
    );
    if (defaultSource.length > 0) {
      setDefaultPayment(defaultSource[0]);
    }
  }, [paymentMethods, customerDefaultPayment]);

  const paymentMethodsList = paymentMethods.map((method) => (
    <CreditCardListItem key={method.id} method={method} />
  ));

  return (
    <article>
      <HeaderBg>
        <h1 className="fw-light text-primary">Οι κάρτες μου</h1>
        <p className="text-muted">
          Δείτε και επεξεργαστείτε όλες τις κάρτες πληρωμών σας
        </p>
        <Breadcrumb className="d-flex justify-content-center">
          <Breadcrumb.Item
            linkAs={Anchor}
            href="/account/dashboard/"
            title="Λογαριασμός"
          >
            <Home size={15} /> Λογαριασμός
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Τρόποι Πληρωμής</Breadcrumb.Item>
        </Breadcrumb>
      </HeaderBg>
      <Container as="section" className="pt-5">
        <div className="d-flex align-items-start justify-content-center flex-wrap mb-5">
          {loading && <CreditCard className="me-0 me-md-4 mb-4" placeholder />}
          {!loading && !error && defaultPayment && (
            <CreditCard
              className="me-0 me-md-4 mb-4"
              brand={defaultPayment.card.brand}
              last4={defaultPayment.card.last4}
              expMonth={defaultPayment.card.exp_month}
              expYear={defaultPayment.card.exp_year}
              holder="ΠΡΟΕΠΙΛΕΓΜΕΝΗ"
            />
          )}
          <Elements
            stripe={stripePromise}
            options={{
              locale: siteLang,
            }}
          >
            <CreditCard addNew />
          </Elements>
        </div>
        {!loading && !error && (
          <ListGroup variant="flush">
            {paymentMethodsList.length > 0 ? (
              paymentMethodsList
            ) : (
              <p className="my-6 text-center">
                Δεν βρέθηκαν κάρτες στον λογαριασμό σας.
              </p>
            )}
          </ListGroup>
        )}
        {loading && <PaymentMethodPlaceholder />}
        {error && (
          <p className="my-6 text-center">
            Παρουσιάστηκε κάποιο πρόβλημα. Δοκιμάστε ξάνα αργότερα.
            <br />
            <Button as={Anchor} href="/account/dashboard/" className="mt-3">
              Επιστροφή στον λογαριασμό
            </Button>
          </p>
        )}
      </Container>
    </article>
  );
};

export default PaymentMethods;
