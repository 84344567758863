import React, { useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

import { Button, Form, Placeholder, Spinner } from "react-bootstrap";

import CreditCardBrand from "./CreditCardBrand";
import { functionsBase } from "../../../config/site-config";
import { useCustomer } from "../../context/CustomerContext";
import { Stripe } from "../../helpers/Icons";

import "../../styles/components/elements/credit-card.scss";

const CreditCard = ({
  className,
  addNew,
  placeholder,
  brand,
  last4,
  expMonth,
  expYear,
  holder,
}) => {
  const [showNewCardInput, setShowNewCardInput] = useState(false);
  const [makeDefault, setMakeDefault] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  if (addNew) {
    const stripe = useStripe();
    const elements = useElements();
    const { customer, setCustomer, setPaymentMethods } = useCustomer();

    const handleAdd = async () => {
      if (!stripe || !elements) return null;
      setLoading(true);
      setError(false);

      fetch(`${functionsBase}/create-setupintent`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ cus_id: customer.id }),
      })
        .then((res) => res.json())
        .then(async (res) => {
          if (res.error) return setError(true);

          const { setupIntent, setupIntentError } =
            await stripe.confirmCardSetup(res.setupIntent.client_secret, {
              payment_method: {
                card: elements.getElement(CardElement),
              },
            });

          if (setupIntentError) return setError(true);

          if (setupIntent) {
            await fetch(`${functionsBase}/customer-payment-methods`, {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({
                cus_id: customer.id,
                params: {
                  limit: 100,
                },
              }),
            })
              .then((r) => r.json())
              .then(async (r) => {
                if (r.error) return setError(true);
                if (makeDefault) {
                  await fetch(`${functionsBase}/update-customer`, {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                      cus_id: customer.id,
                      params: {
                        invoice_settings: {
                          default_payment_method: setupIntent.payment_method,
                        },
                      },
                    }),
                  })
                    .then((rs) => rs.json())
                    .then((rs) => {
                      if (rs.error) setError(true);
                      if (rs.customer) setCustomer(rs.customer);
                    });
                }
                return setPaymentMethods(r.paymentMethods.data);
              })
              .catch(() => setError(true));
          }

          return null;
        })
        .catch(() => setError(true))
        .finally(() => setLoading(false));

      return null;
    };

    const cancelNewCardView = () => {
      setShowNewCardInput(false);
      elements.getElement(CardElement).clear();
      setMakeDefault(false);
    };

    return (
      <div
        className={`credit-card shadow credit-card--new ${className || ""}`}
        data-add={showNewCardInput}
      >
        <button
          type="button"
          className="credit-card__action-new"
          title="Προσθήκη νέας κάρτας"
          onClick={() => setShowNewCardInput(true)}
        >
          <div className="fs-4">Προσθήκη νέας κάρτας</div>
          <span className="display-5">+</span>
        </button>
        <div className="d-grid gap-2 w-100 credit-card__action-add">
          <p
            className="mb-0 small text-end text-muted"
            aria-label="Powered by stripe"
          >
            Secured by <Stripe size={40} />
          </p>
          <CardElement
            className="form-control"
            options={{
              style: { base: { fontSize: "16px", iconColor: "#347e6e" } },
            }}
          />
          <Form.Check
            type="switch"
            label="Ορισμός ως προεπιλεγμένη κάρτα"
            id="newcard.default"
            className={makeDefault ? "text-dark" : "text-muted"}
            checked={makeDefault}
            onChange={() => setMakeDefault(!makeDefault)}
          />
          <div className="d-flex justify-content-center mt-3">
            <Button
              className="me-2 px-3 px-sm-4"
              onClick={handleAdd}
              disabled={loading}
            >
              {loading ? <Spinner animation="grow" size="sm" /> : "Προσθήκη"}
            </Button>
            <Button
              variant="outline-danger"
              className="px-2 px-sm-3"
              disabled={loading}
              onClick={cancelNewCardView}
            >
              Ακύρωση
            </Button>
          </div>
          {error && (
            <p className="mb-0 text-center text-danger">
              Παρουσίαστηκε κάποιο πρόβλημα.
            </p>
          )}
        </div>
      </div>
    );
  }

  if (placeholder) {
    return (
      <Placeholder
        animation="glow"
        className={`mw-100 text-white ${className || ""}`}
      >
        <Placeholder className="credit-card shadow">
          <Placeholder
            className="credit-card__brand"
            style={{ width: "60px", height: "30px" }}
          />
          <Placeholder
            className="credit-card__number"
            style={{ width: "85%", height: "25px" }}
          />
          <span className="credit-card__footer justify-content-between">
            <Placeholder
              className="credit-card__holder"
              style={{ flex: "unset", width: "50%", height: "20px" }}
            />
            <Placeholder
              className="credit-card__expires"
              style={{ width: "70px", height: "20px" }}
            />
          </span>
        </Placeholder>
      </Placeholder>
    );
  }

  return (
    <div className={`credit-card credit-card--brand shadow ${className || ""}`}>
      <div className="credit-card__brand">
        <CreditCardBrand brand={brand} size={60} />
      </div>
      <div className="credit-card__number">**** **** **** {last4}</div>
      <div className="credit-card__footer">
        {holder && <div className="credit-card__holder">{holder}</div>}
        <div className="credit-card__expires">
          <sup>Λήγει</sup>
          <span>
            {expMonth}/{expYear}
          </span>
        </div>
      </div>
    </div>
  );
};

export default CreditCard;
