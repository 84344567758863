import React, { useEffect, useState } from "react";

import {
  Badge,
  Button,
  Col,
  Container,
  Row,
  ListGroup,
  Breadcrumb,
} from "react-bootstrap";

import HeaderBg from "../blocks/HeaderBg";
import Anchor from "../elements/Anchor";
import { useCustomer } from "../../context/CustomerContext";
import { functionsBase } from "../../../config/site-config";
import { useShop } from "../../context/ShopContext";
import { Currency, dateFormat } from "../../helpers";
import InvoiceListPlaceholder from "../elements/placeholders/InvoiceListPlaceholder";
import { Home } from "../../helpers/Icons";

const History = () => {
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { signUp } = useShop();
  const { customer } = useCustomer();

  const statusHelper = {
    draft: {
      text: "ΠΡΟΧΕΙΡΟ",
      bg: "info",
      color: "dark",
      cta: "Ολοκλήρωση",
    },
    open: {
      text: "ΑΝΟΙΧΤΟ",
      bg: "danger",
      color: "white",
      cta: "Πληρωμή",
    },
    paid: {
      text: "ΠΛΗΡΩΜΕΝΟ",
      bg: "success",
      color: "white",
      cta: "Λεπτομέρειες",
    },
    void: {
      text: "ΑΚΥΡΩΜΕΝΟ",
      bg: "danger",
      color: "white",
      cta: "Λεπτομέρειες",
    },
    incollectible: {
      text: "ΠΡΟΒΛΗΜΑΤΙΚΟ",
      bg: "danger",
      color: "white",
      cta: "Λεπτομέρειες",
    },
  };

  useEffect(() => {
    setLoading(true);
    setError(false);

    fetch(`${functionsBase}/customer-invoices`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        cus_id: customer.id,
        params: {
          limit: 100,
        },
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.error) return setError(true);
        return setInvoices(res.invoices.data);
      })
      .catch(() => setError(true))
      .finally(() => setLoading(false));
  }, [customer.id]);

  const invoicesList = invoices.map((invoice) => (
    <ListGroup.Item key={invoice.id}>
      <Row>
        <Col>
          <Badge
            bg={statusHelper[invoice.status].bg}
            text={statusHelper[invoice.status].color}
          >
            {statusHelper[invoice.status].text}
          </Badge>
        </Col>
        <Col>{dateFormat(invoice.period_end * 1000)}</Col>
        <Col>
          {invoice.amount_paid > 0 && (
            <Currency value={invoice.amount_paid / 100} />
          )}
          {invoice.amount_remaining > 0 && (
            <span className="text-danger">
              <Currency value={invoice.amount_remaining / 100} />
            </span>
          )}
        </Col>
        <Col>{invoice.number}</Col>
        <Col className="text-end">
          {invoice.hosted_invoice_url && (
            <Anchor href={invoice.hosted_invoice_url} target="_blank">
              {statusHelper[invoice.status].cta}
            </Anchor>
          )}
        </Col>
      </Row>
    </ListGroup.Item>
  ));

  return (
    <article>
      <HeaderBg>
        <h1 className="fw-light text-primary">Το ιστορικό μου</h1>
        <p className="text-muted">Δείτε το ιστορικό των συναλλαγών σας</p>
        <Breadcrumb className="d-flex justify-content-center">
          <Breadcrumb.Item
            linkAs={Anchor}
            href="/account/dashboard/"
            title="Λογαριασμός"
          >
            <Home size={15} /> Λογαριασμός
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Ιστορικό</Breadcrumb.Item>
        </Breadcrumb>
      </HeaderBg>
      <Container className="mw-1000 pt-5">
        {!loading && !error && (
          <ListGroup variant="flush">
            {invoices.length > 0 ? (
              invoicesList
            ) : (
              <p className="my-6 text-center">
                Δεν βρέθηκαν εγγραφές στον λογαριασμό σας.
                <br />
                <Button as={Anchor} href={signUp} className="mt-3">
                  ΕΓΓΡΑΦΗ
                </Button>
              </p>
            )}
          </ListGroup>
        )}
        {loading && <InvoiceListPlaceholder />}
        {error && (
          <p className="my-6 text-center">
            Παρουσιάστηκε κάποιο πρόβλημα. Δοκιμάστε ξάνα αργότερα.
            <br />
            <Button as={Anchor} href="/account/dashboard/" className="mt-3">
              Επιστροφή στον λογαριασμό
            </Button>
          </p>
        )}
      </Container>
    </article>
  );
};

export default History;
